function checkDimensions() {
  var width = $('body').width();
  if (width > 480) {
    $('.galleryItem a').each(function (i, e) {
      var src = $(e).data('bigtitle');
      $(e).find('img').attr('src', src);
    });
  } else if (width <= 480) {
    $('.galleryItem a').each(function (i, e) {
      var src = $(e).data('title');
      $(e).find('img').attr('src', src);
    });
  }
}
$(document).ready(function ($) {
  checkDimensions();
  $('#main-header .searchIco').click(function () {
    $('#headerForms').slideToggle(400);
  });
  $('.fontSizeSwitch span').click(function (event) {
    event.preventDefault();
    $('body').removeClass().addClass($(this).data('size'));
    $(this).parent().find('span').removeClass('active');
    $(this).addClass('active');
    if (typeof dataLayer === 'object') {
      dataLayer.push({ event: 'clickTo_content', clickTo: event.currentTarget.classList[0] });
    }
  });
  if (Modernizr.csstransitions) {
    $('#article .galleryItem a img').load(function () {
      $(this).addClass('showed');
    });
  } else {
    $('#article .galleryItem a img').load(function () {
      $(this)
        .delay(Math.floor(Math.random() * 500 + 1))
        .animate({ opacity: 1 }, Math.floor(Math.random() * 2500 + 1));
    });
  }
  $(window).resize(function () {
    checkDimensions();
  });
  $('.mainSearch').submit(function () {
    $(this)
      .find('input[name=keyword]')
      .val(encodeURIComponent($(this).find('input[name=q]').val()));
    return true;
  });
});
(function (win) {
  var doc = win.document;
  if (!location.hash && win.addEventListener) {
    window.scrollTo(0, 1);
    var scrollTop = 1,
      getScrollTop = function () {
        return (
          win.pageYOffset ||
          (doc.compatMode === 'CSS1Compat' && doc.documentElement.scrollTop) ||
          doc.body.scrollTop ||
          0
        );
      },
      bodycheck = setInterval(function () {
        if (doc.body) {
          clearInterval(bodycheck);
          scrollTop = getScrollTop();
          win.scrollTo(0, scrollTop === 1 ? 0 : 1);
        }
      }, 15);
    win.addEventListener('load', function () {
      setTimeout(function () {
        if (getScrollTop() < 20) {
          win.scrollTo(0, scrollTop === 1 ? 0 : 1);
        }
      }, 0);
    });
  }
})(this);
